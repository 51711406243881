import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';

import { CheckoutPatientDecisionBlock } from '@patient/components/CheckoutPatientDecisionBlock';
import { TreatmentOverviewCard } from '@patient/components/TreatmentOverviewCard/TreatmentOverviewCard';
import { useGetCheckoutAccordionItems } from '@patient/pages/CheckoutPage/useGetAccordionItems';
import CheckoutAccordian from '@shared/components/CheckoutAccordian';
import { Spinner } from '@shared/components/Spinner';
import { Typography } from '@shared/components/Typography';
import { useGetPractice, useGetTreatmentGuide } from '@shared/data/practice/hooks';
import { Accessor, IPaymentMethod, ITreatmentGuideDecision, PricingType } from '@shared/data/types';
import { PatientMixpanel } from '@shared/utils/patientMixpanel';

export const PatientCheckout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const treatmentGuideId = localStorage.getItem('treatmentGuideId') || '';
  const practiceId = treatmentGuideId.split(':')[0];

  const {
    data: treatmentGuide,
    isLoading: treatmentGuideIsLoading,
    refetch: refetchTreatmentGuide,
  } = useGetTreatmentGuide(treatmentGuideId, Accessor.PATIENT);
  const { data: practice, isLoading: practiceIsLoading } = useGetPractice(practiceId, Accessor.PATIENT);

  const [displayDecisionOptions, setDisplayDecisionOptions] = useState(false);

  const treatmentGuideData = treatmentGuide?.data;

  const treatments = treatmentGuideData?.treatments;

  const financeOnly = Boolean(practice?.data.financeOnly);
  const financeEnabled = Boolean(practice?.data.offersFinance);

  const accordionItems = useGetCheckoutAccordionItems({
    treatmentGuideData,
    financeEnabled,
    practiceName: practice?.data.name,
  });

  useEffect(() => {
    if (treatmentGuideData && treatmentGuideId && practice?.data.name) {
      if (location?.state?.signedIn) {
        PatientMixpanel.identify(treatmentGuideId, practice?.data.name);

        if (treatmentGuideData?.displayIntroduction && !location.state?.hasSeenIntroductionPage) {
          navigate('/patient/login/introduction');
        }

        if (treatmentGuideData.paymentMethod === IPaymentMethod.FINANCE) {
          navigate('/patient/dashboard/finance-confirmation');
        }
      }
    }
  }, [treatmentGuideData, practice, location.state, navigate, treatmentGuideId]);

  if (treatmentGuideIsLoading || practiceIsLoading) return <Spinner />;

  if (!treatmentGuideData) {
    return null;
  }

  if (practice && treatmentGuideData.archived) {
    return <Navigate to="/patient/archived-guide" replace />;
  }

  const displayPayInPracticeConfirmation = treatmentGuideData.paymentMethod === IPaymentMethod.PAY_IN_PRACTICE;

  const displayInterestedConfirmation =
    treatmentGuideData.pricingType !== PricingType.FINAL &&
    treatmentGuideData.decision === ITreatmentGuideDecision.INTERESTED;

  if (!practice?.data) {
    return <p>No practice</p>;
  }

  return (
    <div className="flex flex-col items-center w-full max-w-[40.5rem] mx-auto justify-center gap-10 mt-12">
      <div>
        <Typography variant="h3" className="mb-4">
          {t('patient.checkoutPage.heading', { name: treatmentGuideData.firstName })}
        </Typography>
        <Typography variant="h6" className="mb-4">
          {`${
            (treatments?.length || 0) > 1
              ? t('patient.checkoutPage.subHeadingMultiTreatments')
              : t('patient.checkoutPage.subHeadingSingleTreatment')
          }`}
        </Typography>
        <div className="flex flex-col gap-4 mb-2">
          {treatments &&
            treatments.length > 0 &&
            treatments.map((treatment) => (
              <TreatmentOverviewCard key={treatment.treatmentDefinition} treatment={treatment} />
            ))}
        </div>
        {treatmentGuideData.practitionerNames && treatmentGuideData.practitionerNames?.length ? (
          <div className="flex flex-col pt-3 pb-5 border-b border-grey-200">
            <Typography element="p" variant="b16" className="text-grey-600">
              <>
                {treatmentGuideData.practitionerNames.length === 1 ? (
                  <>
                    {t('patient.checkoutPage.singlePractitioner', {
                      practitioners: treatmentGuideData.practitionerNames[0],
                    })}
                  </>
                ) : (
                  <>
                    {t('patient.checkoutPage.multiplePractitioners', {
                      practitioners: treatmentGuideData.practitionerNames.toString().replace(',', ', '),
                    })}
                  </>
                )}
              </>
            </Typography>
          </div>
        ) : null}

        <CheckoutAccordian icons={{ open: 'minus', close: 'plus' }} items={accordionItems} />
      </div>
      {/* dual column */}
      <CheckoutPatientDecisionBlock
        treatmentGuideId={treatmentGuideId}
        goAheadCheckedDefault={Boolean(
          !treatmentGuideData.decision ||
            (treatmentGuideData.price && treatmentGuideData.decision === ITreatmentGuideDecision.ACCEPTED),
        )}
        patientHasDeclined={treatmentGuideData.decision === ITreatmentGuideDecision.DECLINED}
        patientIsUnsure={treatmentGuideData.decision === ITreatmentGuideDecision.UNSURE}
        financeEnabled={financeEnabled}
        refetchTreatmentGuide={refetchTreatmentGuide}
        treatmentGuide={treatmentGuideData}
        practice={practice.data}
        displayFinanceConfirmation={treatmentGuideData.paymentMethod === IPaymentMethod.FINANCE}
        displayPayInPracticeConfirmation={displayPayInPracticeConfirmation}
        displayDecisionOptions={displayDecisionOptions}
        setDisplayDecisionOptions={setDisplayDecisionOptions}
        displayInterestedConfirmation={displayInterestedConfirmation}
        financeOnly={financeOnly}
        displayPricing={treatmentGuideData.displayPricing}
        enablePayment={treatmentGuideData.pricingType === PricingType.FINAL}
      />
    </div>
  );
};
