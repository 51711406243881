import { forwardRef, InputHTMLAttributes, useEffect, useRef } from 'react';
import { ErrorText } from '@library/components/atoms';
import clsx from 'clsx';

import { Label } from '../Label';

type TickProps = {
  size: string;
};

const CheckedSvg = ({ size }: TickProps) => {
  return (
    <svg
      className={clsx(size, 'absolute hidden peer-checked:block pointer-events-none m-[2px]')}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9723 6.58965C15.3457 6.96309 15.3457 7.56855 14.9723 7.94199L9.50351 13.4107C9.13008 13.7842 8.52461 13.7842 8.15117 13.4107L5.4168 10.6764C5.04336 10.3029 5.04336 9.69746 5.4168 9.32402C5.79024 8.95059 6.3957 8.95059 6.76914 9.32402L8.82734 11.3822L13.6199 6.58965C13.9934 6.21621 14.5988 6.21621 14.9723 6.58965Z"
        fill="white"
      />
    </svg>
  );
};

const inputStyle = `
  relative
  shrink-0
  !ring-0
  rounded
  disabled:bg-foregroundNeutralDisabled
  hover:text-backgroundBrandDefaultHover
  focus:outline-borderBrandDefaultFocus
  focus:outline-2
  focus:outline-offset-1
  border-solid
  border-borderNeutralDefault
  disabled:border
  m-[2px]
  checked:bg-none
  checked:!bg-backgroundBrandDefault
  cursor-pointer
`;

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  testId?: string;
  className?: string;
  /** When indeterminate is true, the checkbox has a horizontal line in the box until the component is interacted with */
  indeterminate?: boolean;
  checkboxSize?: 'xSmall' | 'small' | 'medium' | 'large';
  labelText?: string;
  helperText?: string;
  labelPosition?: 'left' | 'right';
  errorText?: string;
}

export const Checkbox = forwardRef(
  (
    {
      className,
      indeterminate,
      checkboxSize = 'medium',
      testId,
      labelText,
      helperText,
      labelPosition = 'left',
      errorText,
      ...inputProps
    }: CheckboxProps,
    _,
  ) => {
    const cRef = useRef<HTMLInputElement>(null);

    const sizeClasses = () => {
      switch (checkboxSize) {
        case 'xSmall':
        case 'small':
          return 'w-4 h-4';
        case 'medium':
          return 'w-5 h-5';
        case 'large':
          return 'w-6 h-6';
        default:
          return 'w-5 h-5';
      }
    };

    const getLabelSize = (checkboxSize: 'xSmall' | 'small' | 'medium' | 'large') => {
      switch (checkboxSize) {
        case 'small':
          return 'medium';
        case 'medium':
          return 'medium';
        case 'large':
          return 'large';
        default:
          return checkboxSize;
      }
    };

    useEffect(() => {
      if (cRef?.current && indeterminate != undefined) {
        cRef.current.indeterminate = indeterminate;
      }
    }, [cRef, indeterminate]);

    return (
      <div
        className={clsx(
          helperText ? 'items-start' : 'items-center',
          'inline-flex gap-x-3',
          labelPosition === 'right' && 'flex-row-reverse',
        )}
      >
        <input
          ref={cRef}
          role="checkbox"
          type="checkbox"
          className={clsx(inputStyle, className, sizeClasses(), 'peer')}
          data-testid={testId}
          aria-checked={inputProps.checked}
          {...inputProps}
        />
        <CheckedSvg size={sizeClasses()} />
        <div className="flex flex-col gap-1">
          <Label
            className="cursor-pointer !select-none"
            appearance="secondary"
            size={getLabelSize(checkboxSize)}
            helperText={helperText}
            labelText={labelText}
            labelStrong={false}
            htmlFor={inputProps.id}
          />
          {errorText && <ErrorText icon="info" text={errorText} />}
        </div>
      </div>
    );
  },
);

Checkbox.displayName = 'Checkbox';
