import { Fragment, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Accordian } from '@shared/components/Accordian';
import { Button } from '@shared/components/Button';
import { OffCanvas } from '@shared/components/OffCanvas/OffCanvas';
import { Spinner } from '@shared/components/Spinner';
import { Typography } from '@shared/components/Typography';
import { MaxAvailableInstalment, MinAvailableInstalment } from '@shared/content-blocks/FinanceOptionsModule';
import { useGetPracticeInstalments } from '@shared/data/practice/hooks';
import { DivideBuyInstalment, InstalmentType, PricingType } from '@shared/data/types';
import { useEligibilityChecker } from '@shared/hooks/useEligibilityChecker/useEligibilityChecker';
import { divideBuyCurrencyFormatter } from '@shared/utils/numberFormatter';
import { PatientMixpanel } from '@shared/utils/patientMixpanel';

import FinanceModal from '../FinanceModal';

import { Disclaimer } from './Disclaimer';

type props = {
  price: number;
  deposit?: number;
  displayPricing?: boolean;
  treatments: string[];
  practiceName?: string;
  pricingType?: PricingType;
};

export const CheckoutFinanceOptions = ({
  price,
  deposit,
  displayPricing,
  treatments,
  practiceName,
  pricingType,
}: props) => {
  const [offCanvasMenuOpen, setOffCanvasMenuOpen] = useState<boolean>(false);
  const treatmentGuideId = localStorage.getItem('treatmentGuideId') || '';
  const practiceId = treatmentGuideId.split(':')[0];
  const finalBalance = price - (deposit || 0);

  const {
    data: instalments,
    isLoading,
    refetch: getInstalments,
  } = useGetPracticeInstalments({
    amount: String(finalBalance / 100),
    practiceId,
  });

  useEffect(() => {
    if (finalBalance && practiceId) getInstalments();
  }, [finalBalance, practiceId, getInstalments]);

  const instalmentsData = useMemo(() => instalments?.data.terms || [], [instalments?.data]);

  const maxLoanAmount = useMemo(
    () =>
      Math.max(
        ...(
          instalmentsData.filter(
            ({ max_price, type }) => max_price !== null && type !== 'representative_example',
          ) as MaxAvailableInstalment[]
        ).map(({ max_price }) => max_price),
      ).toString(),
    [instalmentsData],
  );

  const minLoanAmount = useMemo(
    () =>
      Math.max(
        ...(
          instalmentsData.filter(
            ({ min_price, type }) => min_price !== null && type !== 'representative_example',
          ) as MinAvailableInstalment[]
        ).map(({ min_price }) => min_price),
      ).toString(),
    [instalmentsData],
  );

  const availableInstalments = useMemo(
    () => instalmentsData.filter(({ available, type }) => type !== 'representative_example' && available != false),
    [instalmentsData],
  );

  const { financeModalRef, openEligibilityChecker } = useEligibilityChecker();
  const showOptions = useMemo(
    () =>
      Boolean(
        availableInstalments.length > 0 &&
          (finalBalance <= parseFloat(maxLoanAmount!) || finalBalance >= parseFloat(minLoanAmount!)),
      ),
    [availableInstalments, finalBalance, maxLoanAmount, minLoanAmount],
  );

  const onOpenEligibilityChecker = () => {
    openEligibilityChecker();
    PatientMixpanel.track('Finance eligibility checked', {
      'Practice name': practiceName,
      'Treatment name': treatments,
      'Treatment price': String(finalBalance / 100),
      'Pricing type': pricingType,
    });
  };

  const representativeExample = instalmentsData.find(
    (item: DivideBuyInstalment) => item.type === InstalmentType.REPRESENTATIVE_EXAMPLE,
  );

  const isAboveMax = finalBalance >= parseFloat(maxLoanAmount!);
  return (
    <div className="flex flex-col items-start w-full">
      {displayPricing !== false && (
        <>
          {availableInstalments && maxLoanAmount && !isLoading ? (
            <>
              {showOptions ? (
                <div>
                  <Typography variant="b16" element="span" className="text-grey-600">
                    {t('patient.myGuidePage.loanCalculatorBlock.financeOptions.text')}
                  </Typography>
                  <div className="flex flex-col gap-y-4 w-full mt-6">
                    {availableInstalments.map((instalment, index) => (
                      <Fragment key={instalment.monthly_instalment_cost}>
                        <div
                          data-testid={`instalment-option-${index}`}
                          className="flex md:flex-row flex-col w-full gap-3"
                        >
                          <Trans
                            i18nKey="patient.myGuidePage.loanCalculatorBlock.financeOptions.instalment.heading"
                            components={{
                              typography: <Typography variant="l16" element="span" className="text-primary-dark" />,
                              monthlyCost: <Typography variant="l16" element="span" className="text-primary-dark" />,
                            }}
                            values={{
                              monthly_instalment_cost: divideBuyCurrencyFormatter(
                                instalment.monthly_instalment_cost,
                                2,
                              ),
                              term_length: instalment.term_length,
                            }}
                          />
                          <Trans
                            i18nKey="patient.myGuidePage.loanCalculatorBlock.financeOptions.instalment.subHeading"
                            components={{
                              typography: <Typography variant="b14" element="span" className="text-primary-dark" />,
                            }}
                            values={{
                              interest_rate: instalment.interest_rate,
                              total_to_pay: divideBuyCurrencyFormatter(instalment.total_to_pay),
                            }}
                          />
                        </div>
                        <hr />
                      </Fragment>
                    ))}
                    <Disclaimer repExample={representativeExample} instalments={availableInstalments} />
                    <div className="bg-primary-light p-6 flex md:flex-row gap-4 md:gap-6 items-center rounded-lg w-full">
                      <Trans
                        i18nKey="patient.myGuidePage.loanCalculatorBlock.links.eligible"
                        components={{
                          typography: <Typography variant="b16" element="span" className="text-primary-dark" />,
                          eligibilityChecker: (
                            <Button
                              className="!p-0 [&>span]:!font-normal &>span]:!text-primary-main [&>span]:!underline-offset-1 [&>span]:!decoration-1"
                              variant="navlink"
                              testId="eligibility-check"
                              text="check if you're eligible"
                              onClick={onOpenEligibilityChecker}
                            />
                          ),
                        }}
                      />
                    </div>
                    <div className="flex flex-col items-start">
                      <Button
                        variant="tertiary"
                        iconRight="help-circle"
                        iconSize={24}
                        onClick={() => setOffCanvasMenuOpen(true)}
                        className={
                          'w-full md:w-fit items-center border !border-primary-dark py-3 px-6 [&>span]:font-medium [&>span]:text-primary-dark hover:!bg-transparent [&>svg]:stroke-2 [&>svg]:stroke-primary-dark hover:[&>svg]:stroke-primary-dark !gap-2.5 bg-transparent rounded-lg'
                        }
                        testId="view-finance-faqs"
                        text={t('patient.myGuidePage.loanCalculatorBlock.links.viewFaqs')}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <Trans
                  i18nKey={
                    isAboveMax
                      ? 'patient.myGuidePage.loanCalculatorBlock.financeOptions.aboveMaximum'
                      : 'patient.myGuidePage.loanCalculatorBlock.financeOptions.belowMinimum'
                  }
                  components={{
                    typography: <Typography variant="b16" element="span" className="text-primary-dark" />,
                  }}
                  values={{
                    max_loan_amount: divideBuyCurrencyFormatter(parseFloat(maxLoanAmount!)),
                    min_loan_amount: divideBuyCurrencyFormatter(parseFloat(minLoanAmount!)),
                  }}
                />
              )}
            </>
          ) : (
            <div className="relative w-full flex items-center py-4">
              <Spinner />
            </div>
          )}
        </>
      )}
      <FinanceModal financeModalRef={financeModalRef} price={finalBalance / 100} />
      <OffCanvas setIsOpen={setOffCanvasMenuOpen} isOpen={offCanvasMenuOpen}>
        <Accordian
          icons={{ open: 'minus', close: 'plus' }}
          className="text-primary-dark"
          header={t('patient.treatmentsPage.faq.title')}
          items={[
            {
              title: t('patient.financePage.faqBlock.question1.text'),
              description: t('patient.financePage.faqBlock.question1.answer'),
            },
            {
              title: t('patient.financePage.faqBlock.question2.text'),
              description: t('patient.financePage.faqBlock.question2.answer'),
            },
            {
              title: t('patient.financePage.faqBlock.question3.text'),
              description: (
                <>
                  {t('patient.financePage.faqBlock.question3.answer')}
                  <ul role="list" className="list-disc ml-6 my-2">
                    <li>{t('patient.financePage.faqBlock.question3.limitMonth12')}</li>
                    <li>{t('patient.financePage.faqBlock.question3.limitMonth24')}</li>
                    <li>{t('patient.financePage.faqBlock.question3.limitMonth36')}</li>
                  </ul>
                  {t('patient.financePage.faqBlock.question3.info')}
                </>
              ),
            },
            {
              title: t('patient.financePage.faqBlock.question4.text'),
              description: t('patient.financePage.faqBlock.question4.answer'),
            },
            {
              title: t('patient.financePage.faqBlock.question5.text'),
              description: t('patient.financePage.faqBlock.question5.answer'),
            },
            {
              title: t('patient.financePage.faqBlock.question6.text'),
              description: t('patient.financePage.faqBlock.question6.answer'),
            },
            {
              title: t('patient.financePage.faqBlock.question7.text'),
              description: t('patient.financePage.faqBlock.question7.answer'),
            },
            {
              title: t('patient.financePage.faqBlock.question8.text'),
              description: t('patient.financePage.faqBlock.question8.answer'),
            },
            {
              title: t('patient.financePage.faqBlock.question9.text'),
              description: t('patient.financePage.faqBlock.question9.answer'),
            },
            {
              title: t('patient.financePage.faqBlock.question10.text'),
              description: (
                <p>
                  {t('patient.financePage.faqBlock.question10.answer')}
                  <a
                    className="text-primary-main underline decoration-2 underline-offset-4"
                    target="_blank"
                    href={t('patient.financePage.faqBlock.question10.link.href')}
                    rel="noreferrer"
                  >
                    {t('patient.financePage.faqBlock.question10.link.text')}
                  </a>
                </p>
              ),
            },
            {
              title: t('patient.financePage.faqBlock.question11.text'),
              description: t('patient.financePage.faqBlock.question11.answer'),
            },
            {
              title: t('patient.financePage.faqBlock.question12.text'),
              description: t('patient.financePage.faqBlock.question12.answer'),
            },
            {
              title: t('patient.financePage.faqBlock.question13.text'),
              description: t('patient.financePage.faqBlock.question13.answer'),
            },
            {
              title: t('patient.financePage.faqBlock.question14.text'),
              description: t('patient.financePage.faqBlock.question14.answer'),
            },
          ]}
        />
      </OffCanvas>
    </div>
  );
};
