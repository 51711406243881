import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { PatientCheckout } from '@library/pages/PatientCheckout/PatientCheckout/PatientCheckout';

import { RoutesCookieWrapper } from '@patient/components/RoutesCookieWrapper';
import { useFeatureFlags } from '@shared/hooks/useFeatureFlags';
import { routePaths } from '@shared/utils/urls';

import AuthWrapper from './templates/AuthWrapper';
const CheckoutVerificationPage = lazy(() => import('@patient/pages/CheckoutVerificationPage'));
const IntroductionPage = lazy(() => import('@patient/pages/IntroductionPage'));
const ErrorPage = lazy(() => import('@shared/pages/ErrorPage'));
const PatientLoginPage = lazy(() => import('@patient/pages/LoginPage'));
const PatientSupport = lazy(() => import('@patient/pages/Support'));
const AuthLayout = lazy(() => import('@patient/templates/AuthLayout'));
const CookieDeclarationPage = lazy(() => import('@patient/pages/CookieDeclarationPage'));
const AccountPage = lazy(() => import('@patient/pages/AccountPage'));
const LinkSentPage = lazy(() => import('@patient/pages/LinkSentPage'));
const TreatmentDefinitionPage = lazy(() => import('@patient/pages/TreatmentDefinition'));
const CheckoutFinanceConfirmationPage = lazy(() => import('@patient/pages/CheckoutFinanceConfirmationPage'));
const CheckoutPage = lazy(() => import('@patient/pages/CheckoutPage'));

const ArchivedGuidePage = lazy(() => import('@patient/pages/ArchiveGuidePage'));
const CheckoutFinanceBackWelcomePage = lazy(() => import('@patient/pages/CheckoutFinanceWelcomeBackPage'));
const PlansMarketing = lazy(() => import('../library/pages/PlansOnboarding/PlansMarketing'));
const PlansCheckout = lazy(() => import('../library/pages/PlansOnboarding/PlansCheckout'));
const PlansSuccess = lazy(() => import('../library/pages/PlansOnboarding/PlansSuccess'));

const PlansMarketingPage = () => {
  const featureFlags = useFeatureFlags();
  return featureFlags.plansOnboarding ? <PlansMarketing /> : <></>;
};

const PlansCheckoutPage = () => {
  const featureFlags = useFeatureFlags();
  return featureFlags.plansOnboarding ? <PlansCheckout /> : <></>;
};

const PlansSuccessPage = () => {
  const featureFlags = useFeatureFlags();
  return featureFlags.plansOnboarding ? <PlansSuccess /> : <></>;
};

const PatientCheckoutPage = () => {
  const featureFlags = useFeatureFlags();
  return featureFlags.quotationApi ? <PatientCheckout /> : <CheckoutPage />;
};

const PlansRoutes = (
  <Route path={routePaths.plans}>
    <Route path={routePaths.marketing} index element={<PlansMarketingPage />} />
    <Route path={routePaths.checkout} index element={<PlansCheckoutPage />} />
    <Route path={routePaths.plansSuccess} index element={<PlansSuccessPage />} />
  </Route>
);

const DashboardRoutes = (
  <>
    <Route path={routePaths.dashboard} element={<AuthWrapper />}>
      <Route index element={<PatientCheckoutPage />} />
      <Route path={routePaths.account} element={<AccountPage />} />
      <Route path={routePaths.financeConfirmation} element={<CheckoutFinanceConfirmationPage />} />
      <Route path={routePaths.financeVerification} element={<CheckoutVerificationPage />} />
      <Route path={routePaths.financeWelcomeBack} element={<CheckoutFinanceBackWelcomePage />} />
      <Route path={routePaths.treatmentDefinition} element={<TreatmentDefinitionPage />} />
      <Route path={routePaths.support} element={<PatientSupport />} />
    </Route>
  </>
);

const PatientRoutes = (
  <Route path={routePaths.patient} element={<RoutesCookieWrapper />}>
    <Route path={routePaths.error} element={<ErrorPage />} />
    <Route index element={<PatientLoginPage />} />
    <Route path={routePaths.cookieDeclaration} element={<CookieDeclarationPage />} />
    {DashboardRoutes}
    {<Route path={routePaths.archivedGuide} element={<ArchivedGuidePage />} />}
    <Route path={routePaths.login} element={<AuthLayout />}>
      <Route index element={<PatientLoginPage />} />
      <Route path={routePaths.linkSent} element={<LinkSentPage />} />
      <Route path={routePaths.introduction} index element={<IntroductionPage />} />
    </Route>
    {PlansRoutes}
  </Route>
);

export default PatientRoutes;
